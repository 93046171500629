import { ApplicationRef, inject, Injectable, InjectionToken } from '@angular/core';
import { LinkDirective } from './link.directive';
import { RouterPreloader } from '@angular/router';
import { LinkHandlerStrategy } from './link-handler-strategy';
import { PrefetchRegistry } from './prefetch-registry.service';
import { WINDOW } from '@owain/tokens/window.provider';
import { filter, takeWhile, tap } from 'rxjs/operators';

const observerSupported = (window: Window | null): boolean =>
  typeof window !== 'undefined' ? !!(window as any).IntersectionObserver : false;
const idleCallbackSupported = (window: Window | null): boolean =>
  typeof window !== 'undefined' ? !!(window as any).requestIdleCallback : false;

export const LinkHandler: InjectionToken<any> = new InjectionToken('LinkHandler');

@Injectable({ providedIn: 'root' })
export class ObservableLinkHandler implements LinkHandlerStrategy {
  private readonly window: Window = inject(WINDOW);
  private readonly applicationRef: ApplicationRef = inject(ApplicationRef);
  private readonly loader: RouterPreloader = inject(RouterPreloader);
  private readonly registry: PrefetchRegistry = inject(PrefetchRegistry);

  private readonly observerSupported: boolean = observerSupported(this.window);
  private readonly idleCallbackSupported: boolean = idleCallbackSupported(this.window);

  private idleCallbackId: number | undefined;
  private subscriptionDone: boolean = false;

  private elementLink = new Map<Element, LinkDirective>();
  private observer: IntersectionObserver | null = this.observerSupported
    ? new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (!this.observer) {
            return;
          }
          if (entry.isIntersecting) {
            const link = entry.target as HTMLAnchorElement;

            const routerLink = this.elementLink.get(link);
            if (!routerLink || !routerLink.urlTree) return;

            this.registry.add(routerLink.urlTree);
            this.observer.unobserve(link);

            this.prefetchIdle();
          }
        });
      })
    : null;

  register(el: LinkDirective) {
    this.elementLink.set(el.element, el);

    if (!this.observer) {
      return;
    }
    this.observer.observe(el.element);
  }

  // First call to unregister will not hit this.
  unregister(el: LinkDirective) {
    if (this.idleCallbackId !== undefined) {
      this.window.cancelIdleCallback(this.idleCallbackId);
    }

    if (!this.observer) {
      return;
    }

    if (this.elementLink.has(el.element)) {
      this.observer.unobserve(el.element);
      this.elementLink.delete(el.element);
    }
  }

  supported() {
    return this.observerSupported;
  }

  private prefetchIdle(): void {
    this.applicationRef.isStable
      .pipe(
        takeWhile(() => !this.subscriptionDone),
        filter(isStable => isStable),
        tap(() => {
          this.subscriptionDone = true;

          this.idleCallbackSupported
            ? (this.idleCallbackId = this.window.requestIdleCallback(() => {
                this.loader.preload().subscribe(() => void 0);
              }))
            : this.loader.preload().subscribe(() => void 0);
        })
      )
      .subscribe();
  }
}

@Injectable({ providedIn: 'root' })
export class PreloadLinkHandler implements LinkHandlerStrategy {
  private readonly window: Window = inject(WINDOW);
  private readonly applicationRef: ApplicationRef = inject(ApplicationRef);
  private readonly loader: RouterPreloader = inject(RouterPreloader);
  private readonly registry: PrefetchRegistry = inject(PrefetchRegistry);

  private readonly idleCallbackSupported: boolean = idleCallbackSupported(this.window);

  private idleCallbackId: number | undefined;
  private subscriptionDone: boolean = false;

  register(el: LinkDirective) {
    const urlTree = el.urlTree;
    if (urlTree) {
      this.registry.add(urlTree);
    }

    this.applicationRef.isStable
      .pipe(
        takeWhile(() => !this.subscriptionDone),
        filter(isStable => isStable),
        tap(() => {
          this.subscriptionDone = true;

          this.idleCallbackSupported
            ? (this.idleCallbackId = this.window.requestIdleCallback(() => {
                this.loader.preload().subscribe(() => void 0);
              }))
            : this.loader.preload().subscribe(() => void 0);
        })
      )
      .subscribe();
  }

  unregister(_: LinkDirective) {
    if (this.idleCallbackId !== undefined) {
      this.window.cancelIdleCallback(this.idleCallbackId);
    }
  }

  supported() {
    return true;
  }
}
